import { addUserBrowserPushAPI, AddUserFavoriteEtfAPI, AuthAPI, deleteUserAPI, DeleteUserPortfolioAPI, GetTokenInfoAPI, GetUserFavoriteEtfListAPI, GetUserPortfolioListAPI, GetUserNotificationAPI, LoginAPI, LogoutAPI, RemoveUserFavoriteEtfAPI, updateUserAlarmAPI, UpdateUserPortfolioAPI, updateUserProfileAPI, UpdateUserNotificationAPI, UserStrategyReferenceAPI, ReferStrategyAPI, DeleteStrategyReferenceAPI, SignUpAPI } from 'core/apis/backtest'
import { Portfolio } from 'core/domain/strategy'
import { User } from "core/domain/user"
import { UserNotification } from 'core/domain/userNotification'
import axios from 'axios'
import { defineStore } from "pinia"

export const useUserStore = defineStore('user', {
  state: (): {
    user?: User
    userFavoriteEtfList?: string[]
    userStrategyList: Portfolio[]
    userReferenceList?: string[]
    userNotificationList: UserNotification[]
  } => {
    return {
      user: undefined,
      userFavoriteEtfList: undefined,
      userStrategyList: [],
      userReferenceList: undefined,
      userNotificationList: []
    }
  },
  getters: {
    loggedInUser: (state) => {
      return state.user
    },
    isAdmin: (state) => {
      if (state.user) {
        return state.user.plan == 'ADMIN'
      } else {
        return false
      }
    },
    backtestLimit: (state) => {
      if (state.user!.plan == 'ADMIN') {
        return 10000
      } else if (state.user!.plan == 'BASIC') {
        return 300
      } else if (state.user!.plan == 'PREMIUM') {
        return 3000
      } else if (state.user!.plan == 'STRATEGY') {
        return 20
      } else {
        return 10
      }
    },
    etfAlarmLimit: (state) => {
      if (state.user!.plan == 'ADMIN') {
        return 100
      } else if (state.user!.plan == 'BASIC') {
        return 20
      } else if (state.user!.plan == 'PREMIUM') {
        return 200
      } else if (state.user?.haveBacktestPass) {
        return 10
      } else if (state.user!.plan == 'STRATEGY') {
        return 10
      } else {
        return 5
      }
    },
    strategyLimit: (state) => {
      if (state.user!.plan == 'ADMIN') {
        return 300
      } else if (state.user!.plan == 'BASIC') {
        return 20
      } else if (state.user!.plan == 'PREMIUM') {
        return 50
      } else if (state.user?.haveBacktestPass) {
        return 10
      } else if (state.user!.plan == 'STRATEGY') {
        return 5
      } else {
        return 0
      }
    },
    aiSummaryLimit: (state) => {
      if (state.user!.plan == 'ADMIN') {
        return 500
      } else if (state.user!.plan == 'BASIC') {
        return 20
      } else if (state.user!.plan == 'PREMIUM') {
        return 50
      } else if (state.user?.haveBacktestPass) {
        return 5
      } else if (state.user!.plan == 'STRATEGY') {
        return 5
      } else {
        return 5
      }
    },
    unreadNotificationCount: ({ userNotificationList }) => {
      return userNotificationList.filter((el) => {
        return !el.is_view
      }).length
    }
  },
  actions: {
    async logout() {
      const logoutResponse = await LogoutAPI()
      this.$reset()
    },
    async login(email: string, password: string) {
      const loginResponse = await LoginAPI(email, password)
      this.user = new User(loginResponse.data)
      this.fetchUserNotification()
    },
    async refreshUser() {
      const response = await GetTokenInfoAPI()
      if (response.data.code === '4012') {
        throw Error(response.data.message)
      }
      this.user = new User(response.data)
    },
    async autoLogin() {
      if (this.user) return true
      try {
        await this.refreshUser()
        this.fetchUserNotification()
        return true
      } catch (error) {
        this.$reset()
        return false
      }
    },
    async socialAuth(platform: string, data: any) {
      const authResponse = await AuthAPI(platform, data)
      this.user = new User(authResponse.data)
      this.fetchUserNotification()
    },
    async snsSignUp(platform: string, data: any) {
      const response = await SignUpAPI(platform, data)
      this.user = new User(response.data)
      this.fetchUserNotification()
    },
    async fetchUserFavoriteEtf() {
      if (!this.user) throw Error('user is none')
      if (!this.userFavoriteEtfList) {
        const response = await GetUserFavoriteEtfListAPI(this.user.uuid)
        this.userFavoriteEtfList = response.data
      }
    },
    isFavorite(symbol: string) {
      if (this.userFavoriteEtfList) {
        return this.userFavoriteEtfList.includes(symbol)
      } else {
        return false
      }
    },
    async toggleFavoriteEtf(symbol: string) {
      if (!this.user) return
      if (!this.userFavoriteEtfList) return

      const idx = this.userFavoriteEtfList.findIndex(s => s == symbol)
      if (idx < 0) {
        try {
          const response = await AddUserFavoriteEtfAPI(this.user.uuid, symbol)
          this.userFavoriteEtfList.push(symbol)
        } catch (error) {
          console.error(error)
          return false
        }
      } else {
        const response = await RemoveUserFavoriteEtfAPI(this.user.uuid, symbol)
        this.userFavoriteEtfList!.splice(idx, 1)
      }
      return true
    },
    async fetchUserStrategy(weight?: boolean) {
      if (!this.user) throw Error('user is none')
      const response = await GetUserPortfolioListAPI(this.user.uuid, Boolean(weight))
      this.userStrategyList = response.data.map((portfolio: any) => {
        return new Portfolio(portfolio)
      })
    },
    async deleteStrategy(portfolioUuid: string) {
      if (!this.user) throw Error('user is none')
      const response = await DeleteUserPortfolioAPI(portfolioUuid)
      const idx = this.userStrategyList.findIndex(s => s.uuid == portfolioUuid)
      this.userStrategyList.splice(idx, 1)
    },
    async updateStrategyTitle(portfolioUuid: string, title: string) {
      if (!this.user) throw Error('user is none')
      const response = await UpdateUserPortfolioAPI(portfolioUuid, title)
      const pf = this.userStrategyList.find(s => s.uuid == portfolioUuid)!
      pf.title = title
    },
    async updateStrategyShare(portfolioUUID: string, share: boolean) {
      if (!this.user) throw Error('user is none')
      const response = await UpdateUserPortfolioAPI(portfolioUUID, undefined, share)
      const pf = this.userStrategyList.find(s => s.uuid == portfolioUUID)!
      pf.share = share
    },
    async updateStrategyAlarm(portfolioUUID: string, alarm: boolean) {
      if (!this.user) throw Error('user is none')
      const response = await UpdateUserPortfolioAPI(portfolioUUID, undefined, undefined, alarm)
      const pf = this.userStrategyList.find(s => s.uuid == portfolioUUID)!
      pf.alarm = alarm
    },
    async withdrawal(reason: string) {
      if (!this.user) return
      const response = await deleteUserAPI(this.user.uuid, reason)
      this.$reset()
    },
    async updateUserProfile(nicknameInput: string, phoneInput: string) {
      if (this.user) {
        const response = await updateUserProfileAPI(this.user.uuid, nicknameInput, phoneInput, this.user.marketingConsent, this.user.newsletterConsent)
        this.user.nickname = nicknameInput
        this.user.phone = phoneInput
      }
    },
    async updateAlarm(key: string, channel: string[]) {
      if (this.user) {
        const response = await updateUserAlarmAPI(key, channel)
        this.user.alarm[key] = channel
      }
    },
    async addBrowserPush(subscription: any) {
      if (this.user) {
        const response = await addUserBrowserPushAPI(subscription)
      }
    },
    async fetchUserNotification() {
      if (this.user) {
        const response = await GetUserNotificationAPI()
        this.userNotificationList = response.data.map((el: UserNotification) => {
          return new UserNotification(el)
        })
      }
    },
    async updateUnreadNotification() {
      if (this.unreadNotificationCount) {
        const response = await UpdateUserNotificationAPI()
        this.userNotificationList.forEach(element => {
          element.is_view = true
        })
      }
    },
    async fetchUserReference() {
      if (!this.user) throw Error('user is none')
      if (!this.userReferenceList) {
        const response = await UserStrategyReferenceAPI()
        this.userReferenceList = response.data.map((el: any) => el.portfolio)
      }
    },
    checkStrategyReference(uuid: string) {
      return Boolean(this.userReferenceList && this.userReferenceList.includes(uuid))
    },
    async referStrategy(uuid: string, t: Function) {
      if (!this.user) return ['LOGIN', t('error.not_logged_in')]
      if (!this.userReferenceList) return ['Warning', t('error.try_again')]
      const idx = this.userReferenceList.findIndex(el => el == uuid)
      if (idx < 0) {
        try {
          const response = await ReferStrategyAPI(uuid)
          this.userReferenceList = [...this.userReferenceList, uuid]
          return ['Success', t('popup.basic_noti_register')]
        } catch (error) {
          if (axios.isAxiosError(error)) {
            switch (error.response?.data.code) {
              case '4001':
                return ['Warning', t('error.already_registered')]
              default:
                console.error(error)
                return ['Warning', t('error.error_occurred')]
            }
          }
        }
      } else {
        const response = await DeleteStrategyReferenceAPI(uuid)
        this.userReferenceList.splice(idx, 1)
        return ['Success', t('popup.basic_noti_remove')]
      }
      return ['', '']
    },
    increaseBacktestCount() {
      if (!this.user) return
      if (this.user.backtestPassCount) {
        this.user.backtestPassCount -= 1
      } else {
        this.user.backtestCount += 1
      }
    }
  },
  persist: {
    storage: sessionStorage,
  },
})

export const usePathStore = defineStore('path', {
  state: (): {
    pathOfBeforeLogin?: string
    mobileHeadingOfStrategy?: string
    selectedAccount?: string
  } => {
    return {
      pathOfBeforeLogin: undefined,
      mobileHeadingOfStrategy: undefined,
      selectedAccount: undefined,
    }
  },
  actions: {
    setPathOfBeforeLogin(path: string) {
      if (path && !path.includes('/user/login') && !path.includes('/user/join') && !path.includes('/user/consent/mail')) this.pathOfBeforeLogin = path
    },
  },
  persist: true,
})
